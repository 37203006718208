import templatesString from './data/templates.lua';

let templates = {
	load: '',
	decoder: '',
	randomLuaCode: '',
};

templatesString.replace(/\r\n/g, '\n').split('--------------------').forEach(value => {
	if (value.startsWith(' Template: ')) {
		let newlinePos = value.indexOf('\n');
		let name = value.substring(' Template: '.length, newlinePos);
		templates[name] = value.substring(newlinePos + 1);
	}
});

export default templates;